import { AXIOS_INSTANCE } from "..";
import { MATKA_SERVICE_URL } from "../../utils/constants";

export const getMatkaMarketAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${MATKA_SERVICE_URL}/matka-market`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatkaAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${MATKA_SERVICE_URL}/matka`);
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatkaBetsAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${MATKA_SERVICE_URL}/${id}/my-bets`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const placeBetMatka = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${MATKA_SERVICE_URL}/bet-lock`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
