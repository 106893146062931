import React, { useEffect } from "react";
import "./matka.scoped.css";
import Slider from "react-slick";
import moment from "moment";
import { getMatkaMarketAPI } from "../../service/matka";
export default function MatkaDashboard() {
  const [matkaGames, setMatkaGames] = React.useState([]);
  const getGames = async () => {
    const { response } = await getMatkaMarketAPI();
    setMatkaGames(response);
  };
  useEffect(() => {
    getGames();
  }, []);
  return (
    <div className="dashboard">
      <div
        style={{
          position: "static",
        }}
        className="row"
      >
        {matkaGames?.map((game) => (
          <div
            onClick={() => {
              window.location.href = "/matka/" + game?.id;
            }}
            className="col-md-3 col-sm-12"
          >
            <div className="matka-round ">
              <div className="details">
                <p className="heading">{game?.matka?.name}</p>
                <p className="subline">
                  {game?.matka?.openTime} To {game?.matka?.openTime} Result at{" "}
                  {game?.matka?.resultTime}
                </p>
              </div>
              <div>
                <p className="number">--</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
