import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { Link, useLocation } from "react-router-dom";

import { getCasinoBetReportAPI } from "../../service/AuthService";
import { CASINOS } from "../../utils/constants";

export default function CasinoMyBets() {
  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const { state } = useLocation();
  const [selectedType, setSelectedType] = React.useState("ALL");

  const getAccountStatement = async () => {
    setLoading(true);

    const { response } = await getCasinoBetReportAPI();

    if (selectedType == "ALL") {
      setData(response);
    } else {
      setData(
        response.filter(
          (item) => item?.casino?.toLowerCase() === selectedType?.toLowerCase()
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getAccountStatement();
  }, [selectedType]);
  useEffect(() => {}, [selectedType]);
  return (
    <div className="position-relative">
      <Link className="btn btn-primary backbutton w-100" to="/dashboard">
        Back to Main Menu
      </Link>

      <main className="warp table-responsive ">
        <div align="center" className="menu">
          <select style={{ width: "90%", margin: 5, padding: 5 }}>
            <option value="ALL">ALL</option>
            {CASINOS.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
        <table
          style={{ color: "black" }}
          className="sesstion-bets table-bordered text-center w-100 my-2 mt-0"
        >
          <tbody>
            <tr
              style={{
                backgroundColor: " rgb(0, 45, 91)",
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <th colspan="12" style={{ fontSize: "18px", padding: "10px" }}>
                CASINO BETS
              </th>
            </tr>
            <tr>
              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td pdr-4"
              >
                CASINO
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                RUNNER NAME
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                AMOUNT
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                RATE
              </th>

              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                PROFIT/LOSS
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                WONBY
              </th>
              <th
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                DATE
              </th>
            </tr>
            {data
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.filter((item) => {
                if (selectedType == "ALL") return true;
                return (
                  item?.casino?.toLowerCase() === selectedType?.toLowerCase()
                );
              })

              .map((item) => (
                <tr>
                  <td>{item?.casino}</td>
                  <td>{item?.runnerName}</td>
                  <td>{item?.amount}</td>
                  <td>{item?.backRate}</td>

                  <td>{item?.profitLoss}</td>
                  <td>{item?.wonBy}</td>
                  <td>
                    {moment(item?.createdAt + "Z").format("DD/MM/YYYY hh:mm A")}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}
