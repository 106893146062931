import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
  getCompleteGamesAPI,
} from "../service/AuthService";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getProfit } from "../utils/constants";
import { cricketBookMakerPL } from "../utils/profitLoss";

export default function ViewCompleteGames() {
  const location = useLocation();
  const [data, setData] = React.useState([]);
  const [accountLogId, setAccountLogId] = React.useState(
    location?.state?.item?.accountLogId
  );

  const [loading, setLoading] = React.useState(false);

  const getAccountStatement = async () => {
    setLoading(true);
    const { response } = await getBetsFromAccountLogs(accountLogId);
    let response_ = response;
    response_.betResponseDTOList = response_.betResponseDTOList.map(
      calculateExposure
    );
    setLoading(false);
    setData(response_);
  };
  useEffect(() => {
    getAccountStatement();
  }, [accountLogId]);

  const calculateExposure = (data) => {
    let isBack = data?.lay_rate ? false : true;
    let rate = isBack ? data?.back_rate : data?.lay_rate;
    let amount = data?.amount;
    let runs = isBack ? data?.back_run : data?.lay_run;
    let exposures = [];
    let teams = data?.event_name?.split("v").sort((a, b) => a - b);
    teams.forEach((team) => {
      let exposure = {
        team: team,
        rate: rate,
        amount: amount,
        runs: runs,
        isBack: isBack,
      };
      let type = isBack ? "back" : "lay";
      let profit = cricketBookMakerPL(rate, amount, type).profit;
      let loss = cricketBookMakerPL(rate, amount, type).loss;
      if (team.trim() === data?.runner_name) {
        exposure.exposure = isBack ? profit : `-${loss}`;
      } else {
        exposure.exposure = isBack ? `-${loss}` : profit;
      }
      exposures.push(exposure);
    });
    return { ...data, exposures };
  };

  return (
    <>
      {loading ? (
        <>
          <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
            <img
              src="/assets/images/loader.png"
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <div class="position-relative view_complete">
        <Link
          className="btn btn-primary backbutton w-100"
          to="/complete_games"
        >
          Back to Statement
        </Link>
        <div class="table-responsive">
          <table class="statement-tables table table-bordered">
            <thead>
              <tr>
                <th colspan="6" class="bg-theme-dark text-center">
                  Match Won By: {data?.winner}
                </th>
              </tr>
              <tr class=" text-white">
                <th>Rate</th>
                <th>Amount</th>
                <th>Mode</th>
                <th>Team</th>
                {data?.betResponseDTOList &&
                  data?.betResponseDTOList[0]?.event_name
                    ?.split("v")
                    ?.sort((a, b) => a - b)
                    ?.map((item) => <td>{item}</td>)}
              </tr>
            </thead>
            <tbody>
              {data.betResponseDTOList
                ?.filter((item) => item?.gameType === "MATCH")
                .map((match) => (
                  <tr>
                    <td>
                      {" "}
                      {match.back_rate ? match?.back_rate : match?.lay_rate}
                    </td>
                    <td>{match?.amount}</td>
                    <td>{match?.back_rate ? "LAGAI" : "KHAI"}</td>
                    <td>{match?.runner_name}</td>
                    {data?.betResponseDTOList[0]?.event_name
                      ?.split("v")
                      ?.map((item) => (
                        <td>
                          {
                            match.exposures.find(
                              (exposure) => exposure.team === item
                            )?.exposure
                          }
                        </td>
                      ))}
                  </tr>
                ))}
              <td
                colspan="6"
                class={`text-center ${
                  data.betResponseDTOList
                    ?.filter((item) => item?.gameType === "MATCH")
                    ?.reduce(
                      (acc, item) =>
                        acc + (item.profit || 0) - (item.loss || 0),
                      0
                    ) > 0
                    ? "text-green"
                    : "text-primary"
                }`}
                style={{ fontSize: "16px" }}
              >
                {data?.betResponseDTOList
                  ?.filter((item) => item?.gameType === "MATCH")
                  .reduce(
                    (acc, item) =>
                      acc + (item.profit || 0) - (item.loss || 0),
                    0
                  ) > 0
                  ? "YOU WON"
                  : "YOU LOSS"}{" "}
                {Math.abs(
                  data?.betResponseDTOList
                    ?.filter((item) => item?.gameType === "MATCH")
                    .reduce(
                      (acc, item) =>
                        acc + (item.profit || 0) - (item.loss || 0),
                      0
                    )
                )}{" "}
                /- COINS
              </td>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table-responsive statement-tables table table-bordered">
            <thead>
              <tr>
                <th colspan="7" class="text-center text-white bg-theme-dark">
                  Session Bets
                </th>
              </tr>
              <tr class="text-white ">
                <th>Session</th>
                <th>Rate</th>
                <th>Amount</th>
                <th>Runs</th>
                <th>Mode</th>
                <th>Dec</th>
                {/* <th>P/L</th> */}
              </tr>
            </thead>
            <tbody>
              {data.betResponseDTOList
                ?.filter((item) => item?.gameType === "FANCY")
                .map((match) => (
                  <tr>
                    <td>
                      {match?.runner_name} <span class="text-danger" />{" "}
                    </td>
                    <td>
                      {match.back_rate ? match?.back_rate : match?.lay_rate}
                    </td>
                    <td>{match?.amount}</td>
                    <td>
                      {match.back_rate ? match?.back_run : match?.lay_run}
                    </td>
                    <td>{match.back_rate ? "YES" : "NO"}</td>
                    <td>{match?.result}</td>
                    {/* <td>{match?.profit ? match?.profit : match?.loss}</td> */}
                  </tr>
                ))}
              <td
                colspan="7"
                class={`text-center ${
                  data.betResponseDTOList
                    ?.filter((item) => item?.gameType === "FANCY")
                    ?.reduce(
                      (acc, item) =>
                        acc + (item.profit || 0) - (item.loss || 0),
                      0
                    ) > 0
                    ? "text-green"
                    : "text-primary"
                }`}
                style={{ fontSize: "16px" }}
              >
                {data?.betResponseDTOList
                  ?.filter((item) => item?.gameType === "FANCY")
                  .reduce(
                    (acc, item) =>
                      acc + (item?.profit || 0) - (item.loss || 0),
                    0
                  ) > 0
                  ? "YOU WON"
                  : "YOU LOSS"}{" "}
                {Math.abs(
                  data?.betResponseDTOList
                    ?.filter((item) => item?.gameType === "FANCY")
                    .reduce(
                      (acc, item) =>
                        acc + (item?.profit || 0) - (item.loss || 0),
                      0
                    )
                )}
                /- coins.
              </td>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table-responsive statement-tables table">
            <thead>
              <tr>
                <th class="bg-theme-dark text-center text-white">
                  Match Session Plus Minus
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class={`text-center ${
                    data?.totalWin > 0 ? "text-green" : "text-primary"
                  }`}
                >
                  {data?.totalWin > 0 ? "YOU WON" : "YOU LOSS"}{" "}
                  {Math.abs(data?.totalWin)}/- coins
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table-responsive statement-tables table">
            <thead>
              <tr>
                <th class="bg-theme-dark text-center text-white">
                  My Commission
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class={`text-center ${
                    data?.profitLossAfterCommission - data?.totalWin >= 0
                      ? "text-green"
                      : "text-primary"
                  }`}
                >
                  {data?.profitLossAfterCommission - data?.totalWin >= 0
                    ? "YOU WON"
                    : "YOU LOSS"}{" "}
                  {Math.abs(data?.profitLossAfterCommission - data?.totalWin)}
                  /- coins
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table-responsive statement-tables table">
            <thead>
              <tr>
                <th class="bg-theme-dark text-white text-center">
                  Net Plus Minus
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class={`text-center ${
                    data?.profitLossAfterCommission > 0
                      ? "text-green"
                      : "text-primary"
                  }`}
                >
                  {data?.profitLossAfterCommission > 0
                    ? "YOU WON"
                    : "YOU LOSS"}{" "}
                  {Math.abs(data?.profitLossAfterCommission)}/- coins.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
