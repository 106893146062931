import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAPI } from "../../service/UserService";
import {
  getAnnouncementAPI,
  getCurrentBetsAPI,
} from "../../service/AuthService";
import { Modal, Offcanvas } from "react-bootstrap";
import { useQuery } from "react-query";
import moment from "moment";

function Header() {
  const location = useLocation();
  const active = location.pathname.split("/")[1];
  const pathname = location.pathname;
  const [exposureTableModal, setExposureTableModal] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = React.useState({});
  const [show, setShow] = useState(false);
  const sideBarRef = React.useRef();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const myMarketQuery = useQuery("myMarket", () =>
    getCurrentBetsAPI({
      betStatus: "MATCHED",
      betType: "ALL",
      pageNumber: 0,
      pageSize: 100,
      search: "",
      sports: true,
    })
  );

  const getUserData = async () => {
    var { response, code } = await getUserDataAPI(userData.id);
    if (code === 200) {
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    }
  };

  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };

  const fetchProfile = () => {
    const interval = setInterval(getUserData, 3000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    getUserData();
    fetchProfile();
    getAnnouncement();
  }, []);
  useEffect(() => {
    setShow(false);
  }, [pathname]);
  const handleClickOutside = (event) => {
    if (sideBarRef?.current && !sideBarRef?.current?.contains(event.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    //close sidebar on click outside

    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, [sideBarRef]);
  if (!userData.token) {
    return (window.location.href = "/");
  }

  return (
    <>
      <Modal
        style={{
          backgroundColor: "transparent",
        }}
        onHide={() => setExposureTableModal(false)}
        show={exposureTableModal}
      >
        <Modal.Header
          style={{
            backgroundColor: "black",
          }}
          closeButton
        >
          <h4>My Market</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            overflowX: "auto",
            textAlign: "center",
          }}
        >
          <div style={{ color: "black" }}>UNSETTLED MATCH BETS</div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Team</th>

                <th>Rate</th>
                <th>Mode</th>
                <th>Amount</th>

                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {myMarketQuery?.data?.response?.unsettledBets?.content
                ?.filter((i) => i.marketName == "BOOKMAKER_ODDS_1")
                ?.map((item) => (
                  <tr>
                    <td>{item?.runner_name}</td>
                    <td>
                      {item?.lay_rate ? item?.lay_rate : item?.back_rate}
                    </td>
                    <td>{item?.lay_rate ? "KHAI" : "LAGAI"}</td>
                    <td>{item?.amount}</td>

                    <td>
                      {moment(item?.created_date + "Z").format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div style={{ color: "black" }}>UNSETTLED SESSION BETS</div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Session</th>

                <th>Rate</th>
                <th>Amount</th>
                <th>Run</th>

                <th>Mode</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {myMarketQuery?.data?.response?.unsettledBets?.content
                ?.filter((i) => i.marketName != "BOOKMAKER_ODDS_1")
                .map((item) => (
                  <tr>
                    <td>{item?.marketName}</td>
                    <td>
                      {item?.lay_rate ? item?.lay_rate : item?.back_rate}
                    </td>
                    <td>{item?.amount}</td>
                    <td>{item?.lay_run ? item?.lay_run : item?.back_run}</td>
                    <td>{item?.lay_rate ? "NO" : "YES"}</td>

                    <td>
                      {moment(item?.created_date + "Z").format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <div ref={sideBarRef}>
        <Offcanvas
          style={{
            width: "250px",
          }}
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header
            style={{
              color: "black",
              backgroundColor: "#488df2ad !important",
            }}
            closeButton
          >
            <Offcanvas.Title
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#488df2ad !important",
              }}
            >
              <img src="/assets/images/logo.png" width="100" alt="" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{
              color: "black",

              fontWeight: "bold",
            }}
          >
            <div ref={sideBarRef} className="">
              <ul class="menu">
                <li>
                  <Link to="/dashboard">
                    <i
                      style={{
                        marginRight: "14px",
                      }}
                      className="far fa-home fa-fw"
                    />
                    Dashboard
                  </Link>
                </li>
                <li>
                  <a href="/statement">
                    <i
                      style={{
                        marginRight: "15px",
                      }}
                      className="far fa-book-reader"
                    />
                    Statement
                  </a>
                </li>
                <li>
                  <a href="/ledger">
                    <i
                      style={{
                        marginRight: "15px",
                      }}
                      className="far fa-book-reader"
                    />
                    Ledger
                  </a>
                </li>
                <li>
                  <a href="/complete_games">
                    <i
                      style={{
                        marginRight: "12px",
                      }}
                      className="fas fa-gamepad fa-fw"
                    />
                    Complete Games
                  </a>
                </li>
                <li>
                  <a href="/change_password">
                    <i
                      style={{
                        marginRight: "12px",
                      }}
                      className="fas fa-lock fa-fw"
                    />
                    Change Password
                  </a>
                </li>
              </ul>

              <ul class="menu">
                <li>
                  <Link to="/rules">
                    <i
                      style={{
                        marginRight: "16px",
                      }}
                      className="far fa-info-circle"
                    />
                    Rules
                  </Link>
                </li>
              </ul>
              <ul class="menu">
                <li
                  onClick={() => {
                    dispatch({ type: "accountData/logOut" });
                    localStorage.clear();
                    window.location.href = "/";
                  }}
                >
                  <Link>
                    <i
                      style={{
                        marginRight: "16px",
                      }}
                      className="far fa-sign-out-alt fa-fw"
                    />
                    Log Out
                  </Link>
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <nav className="bg-theme-dark p-0 navbar navbar-expand-navbar navbar-dark">
        <div className="container-fluid">
          <span
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div
              onClick={handleShow}
              style={{
                color: "black",
                backgroundColor: "white",
                padding: "2px 7px",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <i class="fa-solid fa-bars" aria-hidden="true" />
            </div>
            <div className="d-flex align-items-center">
              <React.Fragment key="right" />
              <Link to="/dashboard" className="logo text-logo">
                <img
                  style={{
                    width: "100px",
                  }}
                  className="logo"
                  src="/assets/images/logo.png"
                />
              </Link>
            </div>
          </span>
          <div className="annoucement">
            <marquee>{announcement?.announcement}</marquee>
          </div>

          <div
            onClick={() => {
              myMarketQuery.refetch();
              setExposureTableModal(true);
            }}
            className="exposure"
            style={{ marginTop: "70px" }}
          >
            <center className="flex-count">
              <p>U/C</p>
              <p>
                <span className="">{userData.liability}/-</span>
              </p>
            </center>
          </div>
          <div className="profile-details text-center">
            <p className="m-0">{userData?.username}</p>
            <p className="m-0">
              chips: {userData?.balance + userData?.liability}
            </p>
          </div>
        </div>
      </nav>

      {/*  <div className="list-tabs-top">
        <Link to="/dashboard">
          <i className="far fa-home fa-fw" /> Dashboard
        </Link>

       
        <ul class="menu">
          <li>
            <a href="#">
              <i class="fas fa-sitemap" />
              Report
            </a>
            <ul class="submenu">
              <li>
                <a href="/statement">
                  <i className="far fa-book-reader" />
                  Statement
                </a>
              </li>
              <li>
                <a href="/ledger">
                  <i className="far fa-book-reader" />
                  Ledger
                </a>
              </li>
              <li>
                <a href="/complete_games">
                  <i className="fas fa-gamepad fa-fw" /> Complete Games
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="menu">
          <li>
            <a href="#">
              <i class="fas fa-user-circle" />
              Profile
            </a>
            <ul class="submenu">
              <li>
                <Link to="/profile">
                  <i className="far fa-user-circle" />
                  Profile
                </Link>
              </li>
              <li>
                <Link to="/change_password">
                  <i className="far fa-lock-open-alt fa-xl" />
                  Change Password
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <Link to="/rules">
          <i className="far fa-info-circle" />
          Rules
        </Link>

        <Link
          onClick={() => {
            dispatch({ type: "accountData/logOut" });
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          <i className="far fa-sign-out-alt fa-fw" />
          LOGOUT
        </Link>
      </div> */}
    </>
  );
}

export default Header;
