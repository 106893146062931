import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';



export default function Profile() {
    const userData = useSelector((state) => state.account.userData);
    console.log(userData);
    return (
        <>
            <div className="position-relative">
                <Link className="btn btn-primary backbutton w-100" to="/dashboard">Back to Main Menu</Link>
                <div className="container-fluid">
                    <div className="row">
                        <div className="p-0 text-center col-sm-12">
                            <h5 className="bg-theme-light profile-title">Rate Information</h5>
                        </div>
                        <div className="col-sm-4 col-4">Rate Diff.</div>
                        <div className="col-sm-4 col-4"><select className="w-100">
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select></div>
                        <div className="col-sm-4 col-4"><button type="button" className="btn btn-primary btn-sm">Update</button></div>
                    </div>
                    <div className="row">
                        <div className="p-0 text-center mt-3 col-sm-12">
                            <h5 className="bg-theme-dark profile-title">Personal Information</h5>
                        </div>
                        <div className="col-sm-6 col-6">Client Code:</div>
                        <div className="col-sm-6 col-6">{userData.username}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-6">Client Name:</div>
                        <div className="col-sm-6 col-6">{userData.username}</div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-6 col-6">Date Of Joining:</div>
                        <div className="col-sm-6 col-6">---</div>
                    </div> */}
                </div>
            </div>

        </>
    )
}
