import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dashboardBannerAPI } from "../service/AuthService";
import Matches from "./Matches";

export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  useEffect(() => {
    getBannerImages();
  }, []);
  return (
    <>
      <div className="position-relative">
        <div className="slider-bottom">
          {/* <Slider {...settings}>
            <div class="game-banner-link">
              <Link>
                <img src="/assets/images/banner.jpg" alt="game-banner" />
              </Link>
            </div>
            <div class="game-banner-link">
              <Link>
                <img src="/assets/images/b1.jpg" alt="game-banner" />
              </Link>
            </div>
            <div class="game-banner-link">
              <Link>
                <img src="/assets/images/b2.jpg" alt="game-banner" />
              </Link>
            </div>
          </Slider> */}
          {/* {homeImages?.map((item) => (
              <div class="game-banner-link">
                <Link>
                  <img src={item} alt="game-banner" />
                </Link>
              </div>
            ))} */}
        </div>
        <div className="container-fluid p-0">
          {/* <InplayContainer /> */}
          <Matches />
        </div>
      </div>

      {/* <div className="container dashboard_content">
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={4}>
            <Link to="/matches" className="card card-1 card-color active">
              <SvgComponent.CricketSvg /> LIVE MATCH
            </Link>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Link to="/rules" className="card card-1 card-color">
              <SvgComponent.RulesSvg /> RULES
            </Link>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: "25px" }}
        >
          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={4}>
            <Link to="/ledgers" className="card card-1 card-color">
              <SvgComponent.LedgerSvg /> LEDGER
            </Link>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Link to="/change_password" className="card card-1 card-color">
              <SvgComponent.PasswordSvg /> PASSWORD
            </Link>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: "25px" }}
        >
          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={4}>
            <Link to={"/upcoming"} className="card card-1 card-color">
              <SvgComponent.UpcomingSvg /> UPCOMING
            </Link>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Link to="/games" className="card card-1 card-color">
              <SvgComponent.GamesSvg /> GAMES
            </Link>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: "25px" }}
        >
          <Grid item xs={12} sm={4}>
            <Link to={"/settings"} className="card card-1 card-color">
              <SvgComponent.SettingSvg /> SETTINGS
            </Link>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Link to="/tournaments" className="card card-1 card-color">
              <SvgComponent.TournamentSvg /> TOURNAMENT
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: "25px" }}
        >
          <Grid item xs={12}>
            <a
              className="card large_card card-1 card-color wp-card"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgComponent.TournamentSvg /> DEPOSIT/WITHDRAWAL - WHATSAPP
            </a>
          </Grid>
        </Grid>

        <Grid container spacing={3} justify="center" className="slider-bottom">
          <Grid item xs={12} className="p-0">
            <Slider {...settings}>
              {homeImages?.map((item) => (
                <div class="game-banner-link">
                  <Link>
                    <img src={item} alt="game-banner" />
                  </Link>
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </div> */}
    </>
  );
}
