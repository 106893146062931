import React, { useEffect } from "react";
import Header from "./components/Header";

import { Link, useNavigate } from "react-router-dom";
import SvgComponent from "./components/SvgComponent";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../service/cricket";
import moment from "moment";
import MatkaDashboard from "./matka/MatkaDashboard";
import { getAllSoccerMatchListAPI } from "../service/soccer&tennis";

const Matches = ({ isUpcoming }) => {
  const dispatch = useDispatch();
  const [selectedGame, setSelectedGame] = React.useState("cricket");

  const userData = useSelector((state) => state.account.userData);

  const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      ?.filter((item) => {
        if (isUpcoming) {
          return !item?.inPlay;
        } else {
          return true;
        }
      })
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );

  const naviagte = useNavigate();

  const getCricketMatches = async () => {
    try {
      const { response, code } = await getAllMatchesOddsCricketAPI();
      const data = await getAllSoccerMatchListAPI();
      console.log(data);
      if (code == 200) {
        let counts = await getBetCount();
        response.map((item) => {
          let countData =
            counts?.betCount[
              Object.keys(counts.betCount).find(
                (count) => count == item?.cricketId
              )
            ];

          item.matchCount = countData?.matchCount;
          item.fancyCount = countData?.fancyCount;
          return item;
        });

        dispatch({
          type: "gameData/setCricketGames",
          payload: response,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let interval;
    getCricketMatches();
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };

  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };

  return (
    <>
      <div className="position-relative">
        <main className="warp">
          <ul className="games-types-top">
            <li
              onClick={() => setSelectedGame("cricket")}
              className={`cricket-button ${
                selectedGame == "cricket" ? "active" : ""
              }`}
            >
              <img
                style={{
                  width: "100%",
                }}
                className="btn-img"
                src="./assets/images/crickett.png
              "
              />
            </li>

            <li
              onClick={() => setSelectedGame("casino")}
              className={`Casino ${selectedGame == "casino" ? "active" : ""}`}
            >
              <img
                style={{
                  width: "100%",
                }}
                className="btn-img"
                src="./assets/images/casinot.png
              "
              />
            </li>
            <li
              onClick={() => setSelectedGame("matka")}
              className={`Matka ${selectedGame == "matka" ? "active" : ""}`}
            >
              <img
                style={{
                  width: "100%",
                }}
                className="btn-img"
                src="./assets/images/matkat.png"
              />
            </li>
          </ul>

          {selectedGame == "cricket" ||
          selectedGame === "soccer" ||
          selectedGame == "tennis" ? (
            <>
              <div>
                {cricketData
                  ?.filter((item) => item?.inPlay)
                  ?.map((gameEvent) => (
                    <div
                      className={`old-matches-list ${
                        isUpcoming ? "" : "live-match"
                      }`}
                    >
                      <div className="list-matches">
                        <div onClick={() => navigateToGame(gameEvent)}>
                          <h6>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                              className="flex"
                            >
                              {selectedGame == "cricket" ? (
                                <i class="far fa-cricket fa-fw blink" />
                              ) : selectedGame == "soccer" ? (
                                <i class="far fa-futbol fa-fw blink" />
                              ) : selectedGame == "tennis" ? (
                                <i class="far fa-tennis-ball fa-fw blink" />
                              ) : null}
                              <span className="d-inline-flex align-items-center float-left mx-2">
                                <div className="blink" />
                              </span>
                              <span
                                style={{
                                  paddingRight: 20,
                                }}
                              >
                                {gameEvent.eventName}
                              </span>
                              <div
                                style={{
                                  marginLeft: 10,
                                  position: "absolute",
                                  right: 0,
                                }}
                                className="ant-col gx-bg-flex gx-px-2 gx-align-items-center gx-justify-content-center ant-col-lg-4 ant-col-xl-5"
                              >
                                <span className="gx-mx-1 gx-text-primary gx-fs-lg">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 256 256"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M216,60H157l27.52-27.52a12,12,0,0,0-17-17L128,55,88.49,15.51a12,12,0,0,0-17,17L99,60H40A20,20,0,0,0,20,80V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V80A20,20,0,0,0,216,60Zm-4,136H44V84H212Z" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </h6>
                          <h5>
                            {moment(
                              gameEvent.eventTime
                                ? gameEvent.eventTime
                                : gameEvent.eventDate
                            ).format("DD MMM hh:mm A")}
                          </h5>
                          <div className="match-info" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {!isUpcoming && (
                <div>
                  {cricketData
                    ?.filter((item) => !item?.inPlay)
                    .map((gameEvent) => (
                      <div
                        className={`old-matches-list ${
                          isUpcoming ? "" : "live-match"
                        }`}
                      >
                        <div className="list-matches">
                          <div onClick={() => navigateToGame(gameEvent)}>
                            <h6>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                                className="flex"
                              >
                                <span className="d-inline-flex align-items-center float-left ">
                                  <div className="blink" />
                                </span>
                                <span
                                  style={{
                                    paddingRight: 20,
                                  }}
                                >
                                  {gameEvent.eventName}
                                </span>
                                <div
                                  style={{
                                    marginLeft: 10,
                                    position: "absolute",
                                    right: 0,
                                  }}
                                  className="ant-col gx-bg-flex gx-px-2 gx-align-items-center gx-justify-content-center ant-col-lg-4 ant-col-xl-5"
                                >
                                  <span className="gx-mx-1 gx-text-primary gx-fs-lg">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth={0}
                                      viewBox="0 0 256 256"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M216,60H157l27.52-27.52a12,12,0,0,0-17-17L128,55,88.49,15.51a12,12,0,0,0-17,17L99,60H40A20,20,0,0,0,20,80V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V80A20,20,0,0,0,216,60Zm-4,136H44V84H212Z" />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </h6>
                            <h5>
                              {moment(
                                gameEvent.eventTime
                                  ? gameEvent.eventTime
                                  : gameEvent.eventDate
                              ).format("DD MMM hh:mm A")}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          ) : null}

          {selectedGame == "casino" ? (
            <>
              <div
                className="container-fluid"
                style={{ padding: "20px 27px" }}
              >
                <div className="row">
                  {/*  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 5,
                    }}
                  >
                    <Link to="/casino/ander-bahar">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/ab20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Andar Bahar
                        </p>
                      </div>
                    </Link>
                  </div> */}

                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 5,
                    }}
                  >
                    <Link to="/casino/live-teen20">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="	https://tvspro99.com/assets/images/casino-images/rg_teen_patti.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          className="btn btn-primary"
                          style={{
                            margin: 0,
                            padding: 5,
                            width: "100%",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Teen Patti T20
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 5,
                    }}
                  >
                    <Link to="/casino/lucky7">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="	https://tvspro99.com/assets/images/casino-images/Lucky7-A.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          className="btn btn-primary"
                          style={{
                            margin: 0,
                            padding: 5,
                            width: "100%",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Lucky 7A
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 5,
                    }}
                  >
                    <Link to="/casino/dragon-tiger">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="	https://tvspro99.com/assets/images/casino-images/dt-20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          className="btn btn-primary"
                          style={{
                            margin: 0,
                            padding: 5,
                            width: "100%",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Dragon Tiger T20
                        </p>
                      </div>
                    </Link>
                  </div>
                  {/* <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 5,
                    }}
                  >
                    <Link to="#">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/amar.jpeg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Amar Akbar Anthony
                        </p>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </>
          ) : null}
          {selectedGame == "matka" ? <MatkaDashboard /> : null}
        </main>
      </div>
    </>
  );
};

export default Matches;
